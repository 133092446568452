import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { FaSquarePen, FaTrash, FaUserPen } from "react-icons/fa6";
import {
  capitalizeEachWord,
  getClassRecord,
  getStudent,
  showSwal,
} from "../../AppManager";

function AdminStudentDetails() {
  const { record, setClassRecord, setCurrClass, passMarks } =
    useOutletContext();
  const { id } = useParams();
  const [student, setStudent] = useState({
    name: "",
    matric: "",
    email: "",
    class: "",
    score: 1000,
    location: "",
    trainingClass: "",
    centre: "",
    teacher: "",
    attendance: "",
    image: "user.png",
  });
  const [page, setPage] = useState(true);
  const [pageFlip, setPageFlip] = useState(true);
  const [classMark, setClassMark] = useState(true);
  const { pathname } = useLocation();
  const returnPath = pathname.slice(0, pathname.indexOf(`/${id}`));
  const navigate = useNavigate();

  useEffect(() => {
    getStudent(id, record)
      .then((data) => setStudent(data))
      .catch(() => {
        showSwal("error", "Student not Found");
        navigate(returnPath, { replace: true });
      });
  }, [id, record, navigate, returnPath]);

  useEffect(() => {
    document.title = student?.name ? student.name : "Student Details";
  }, [student]);

  useEffect(() => {
    if (student.name !== "") {
      getClassRecord(student.trainingClass, student.class)
        .then((data) => setClassRecord(data))
        .catch((err) => {
          showSwal("error", "Student not Found");
          navigate(returnPath, { replace: true });
        });
      setCurrClass(student.class);
    }
  }, [student, setClassRecord, setCurrClass, navigate, returnPath]);

  function togglePage() {
    setPageFlip((oldPageFlip) => !oldPageFlip);
    setTimeout(() => {
      setPage((oldPage) => !oldPage);
    }, 250);
  }

  useEffect(() => {
    for (const className in passMarks) {
      if (Object.hasOwnProperty.call(passMarks, className)) {
        const clas = passMarks[className];
        if (
          student.class.toLowerCase().indexOf(className.toLowerCase()) !== -1
        ) {
          setClassMark(clas);
        }
      }
    }
  }, [passMarks, student]);

  return (
    <div className="details-card-wrapper">
      {!student?.name && (
        <div
          className="loader-item"
          style={{ width: "220px", height: "280px" }}
        >
          <div className="ruc-watermark">
            <div>
              <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
              <p>RUC Training Unit</p>
            </div>
          </div>
        </div>
      )}
      {student?.name && (
        <div className={`details-card ${pageFlip ? "" : "rotate"}`}>
          {page && (
            <div className="front" onClick={togglePage}>
              <div className="details-img">
                <img
                  onError={(e) => {
                    if (e.target.src !== "/user.png") {
                      e.target.onerror = null;
                      e.target.src = "/user.png";
                    }
                  }}
                  src={`/images/students/${student.image}`}
                  alt={capitalizeEachWord(student.name)}
                />
              </div>
              <span>{capitalizeEachWord(student.name)}</span>
              <span>Student</span>
              <span className="manage" style={{ width: "120px" }}>
                <Link to="edit">
                  <FaUserPen style={{ fontSize: "1.1em" }} />
                </Link>
                <Link to="special" state={{ student }}>
                  <FaSquarePen style={{ fontSize: "1em" }} />
                </Link>
                <Link to="delete">
                  <FaTrash style={{ fontSize: "0.9em" }} />
                </Link>
              </span>
            </div>
          )}
          {!page && (
            <div className="back" onClick={togglePage}>
              <div>
                <h3>Name</h3>
                <span>{capitalizeEachWord(student.name)}</span>
              </div>
              <div>
                <h3>Matric Number</h3>
                <span>{student.matric.toUpperCase()}</span>
              </div>
              <div>
                <h3>E-Mail</h3>
                <span style={{ wordBreak: "break-all" }}>
                  <a href={`mailto: ${student.email}`}>{student.email}</a>
                </span>
              </div>
              <div>
                <h3>Attendance</h3>
                <span>{student.attendance}</span>
              </div>
              {student.score && parseInt(student.score) !== 1000 && (
                <div>
                  <h3>Exam Score</h3>
                  <span
                    style={{
                      color:
                        parseInt(classMark) <= parseInt(student.score)
                          ? "#90E18D"
                          : "red",
                      textShadow: "0 0 1px #FFFFFF",
                      fontWeight: "bolder",
                    }}
                  >
                    {student.score}%
                  </span>
                </div>
              )}
              <div>
                <h3>Centre</h3>
                <span>{student.centre}</span>
              </div>
              <div>
                <h3>Training Class</h3>
                <span>{student.trainingClass}</span>
              </div>
              <div>
                <h3>Class</h3>
                <span>{student.class}</span>
              </div>
              <div>
                <h3>Location</h3>
                <span>{student.location}</span>
              </div>
              <div>
                <h3>Teacher</h3>
                <span>{capitalizeEachWord(student.teacher)}</span>
              </div>
            </div>
          )}
          <div className="ruc-watermark">
            <div>
              <img src="/images/Bible Study Icon.png" alt="RUC Training Unit" />
              <p>RUC Training Unit</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminStudentDetails;
