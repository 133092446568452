import React, { useEffect, useState } from "react";
import { FaImage, FaSpinner } from "react-icons/fa6";
import { LiaCheckCircle, LiaTimesCircle } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import { checkExtension, setCarousel } from "../../../AppManager";

export default function HomeImageEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ image: "" });
  const [uprogress, setUprogess] = useState(0);
  const [ustate, setUstate] = useState("");
  const [uploading, setUploading] = useState(false);
  const [imgErr, setImgErr] = useState({
    name: "",
    reason: "",
    status: true,
  });
  const [status, setStatus] = useState({
    status: "",
    type: "",
    message: "",
  });

  useEffect(() => {
    if (!id || id > 5) {
      navigate("/admin/special/images", { replace: true });
    }
  }, [id, navigate]);

  function handleChange(e) {
    const { name, value, type, files } = e.target;
    setFormData((oldFormData) => {
      if (type === "file" && files[0]) {
        if (files[0].size <= 1024000 && checkExtension(files[0].name)) {
          setImgErr({ name: "", reason: "", status: true });
          return { ...oldFormData, [name]: type === "file" ? files[0] : value };
        } else {
          if (files[0].size > 1024000) {
            setImgErr({ name, reason: "size", status: false });
          } else if (!checkExtension(files[0].name)) {
            setImgErr({ name, reason: "format", status: false });
          }
        }
        return oldFormData;
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setUploading(true);
      const res = await setCarousel({ id, image: formData.image });

      if (res === "Success") {
        setUstate("Uploading...");
        const interv = setInterval(() => {
          setUprogess((oldProgress) => {
            if (oldProgress >= 100) {
              clearInterval(interv);
            }
            const rand = Math.floor(Math.random() * 25 + 15);
            const add = oldProgress + rand;
            return add > 100 ? 100 : add;
          });
        }, 500);

        setTimeout(() => {
          setStatus({
            status: "success",
            type: "success",
            message: "Updated Successfully. Redirecting...",
          });
          setTimeout(() => {
            navigate("/admin/special/images", { replace: true });
          }, 2000);
        }, 3000);
      } else {
        setTimeout(() => {
          setStatus({
            status: "error",
            type: "failed",
            message: "Update Failed",
          });
          setUploading(false);
        }, 1000);
      }
    } catch (error) {
      setTimeout(() => {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setUploading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    document.title = "Edit Image";
  }, []);

  return (
    <div className="attendanceform">
      <div className="formwrapper">
        <div
          className="wrapper"
          style={{
            height:
              status.message !== ""
                ? uploading !== false
                  ? "370px"
                  : "320px"
                : uploading !== false
                ? "340px"
                : "300px",
          }}
        >
          <div className="form-box login">
            <h2>Edit Image</h2>
            <form onSubmit={handleSubmit}>
              {status.message !== "" && (
                <p className="status-wrapper" id="status-wrapper">
                  <span className={`status-message ${status.type}`}>
                    {status.message}
                  </span>
                  <LiaTimesCircle
                    className="status-close"
                    onClick={() => {
                      setStatus({ status: "", type: "", message: "" });
                    }}
                  />
                </p>
              )}
              <div className="input-box">
                <span className="icon">
                  <FaImage />
                </span>
                <input
                  readOnly={uploading}
                  type="file"
                  name="image"
                  onChange={handleChange}
                  id="uuploadedfile"
                  className="input"
                  accept="image/*"
                  style={{ marginTop: "5px" }}
                />
                <label>
                  New Image{" "}
                  <span style={{ fontSize: "0.7em" }}>(max = 1MB)</span>
                </label>
                {imgErr.name === "image" &&
                  imgErr.reason === "size" &&
                  !imgErr.status && (
                    <p style={{ marginTop: "0px" }}>Max Size Exceeded</p>
                  )}
                {imgErr.name === "image" &&
                  imgErr.reason === "format" &&
                  !imgErr.status && (
                    <p style={{ marginTop: "0px" }}>Invalid File Format</p>
                  )}
              </div>
              <button
                type="submit"
                disabled={!imgErr.status || uploading}
                className="btn"
              >
                {uploading ? (
                  uprogress === 100 ? (
                    "Finishing..."
                  ) : (
                    <FaSpinner className="spinner" />
                  )
                ) : (
                  "Update"
                )}
              </button>
              {uploading && uprogress !== 0 && (
                <div className="upload-status">
                  <div id="upload-progress">
                    <div
                      id="upload-progress-bar"
                      className="loader-item"
                      style={{
                        width: uprogress + "%",
                        backgroundImage:
                          uprogress !== 100
                            ? "linear-gradient(110deg, #002F63 8%, #1257a7 18%, #002F63 33%)"
                            : "linear-gradient(110deg, green 8%, yellowgreen 18%, green 33%)",
                      }}
                    ></div>
                    <label>{Math.floor(uprogress)}%</label>
                  </div>
                  <div
                    style={{
                      fontSize: "0.8em",
                      fontFamily: "Georgia, 'Times New Roman', Times, serif",
                    }}
                  >
                    {uprogress === 100 ? (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontFamily:
                            "Georgia, 'Times New Roman', Times, serif",
                        }}
                      >
                        <LiaCheckCircle
                          style={{
                            fontSize: "1.2em",
                            marginRight: "2px",
                            color: "green",
                          }}
                        />
                        Upload Completed
                      </span>
                    ) : (
                      ustate
                    )}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
