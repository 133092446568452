import React, { useEffect, useState } from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";
import {
  NavLink,
  Outlet,
  redirect,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  getClassPassMarks,
  getGeneralOverview,
  getMaximumStudents,
} from "../AppManager";
import { clearUser, getCurrUser, getTokenDetails } from "../Auth";
import Offline from "../components/Offline";

export async function loader({ request }) {
  const { pathname } = new URL(request.url);
  const currUser = await getCurrUser();
  if (currUser.position !== "admin") {
    return redirect(`/login/admin?message=Login Required&next=${pathname}`);
  }
  const Record = await getGeneralOverview();
  return { Record, currUser };
}

function AdminOverview() {
  const path = useLocation().pathname;
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [preventRefetch, setPreventRefetch] = useState(false);
  const { Record, currUser } = useLoaderData();
  const [record, setRecord] = useState(Record);
  const [maxStudents, setMaxStudents] = useState(0);
  const [passMarks, setPassMarks] = useState({
    believers: "",
    baptismal: "",
    workers: "",
  });
  const [currClass, setCurrClass] = useState("");
  const [classRecord, setClassRecord] = useState(Record);
  const [off, setOff] = useState(false);

  useEffect(() => {
    document.title = "Overview";

    getMaximumStudents()
      .then((data) => setMaxStudents(data))
      .catch((err) => err);

    getClassPassMarks()
      .then((data) => setPassMarks(data))
      .catch((err) => err);
  }, []);

  useEffect(() => {
    let refetch = "";
    if (!preventRefetch) {
      refetch = setInterval(() => {
        getGeneralOverview()
          .then(
            (data) =>
              JSON.stringify(data) !== JSON.stringify(record) && setRecord(data)
          )
          .catch((err) => err);

        getMaximumStudents()
          .then((data) => data !== maxStudents && setMaxStudents(data))
          .catch((err) => err);

        getClassPassMarks()
          .then(
            (data) =>
              JSON.stringify(data) !== JSON.stringify(passMarks) &&
              setPassMarks(data)
          )
          .catch((err) => err);
      }, 5000);
    }

    return () => {
      clearInterval(refetch);
    };
  }, [path, record, preventRefetch, maxStudents, passMarks]);

  const navigate = useNavigate();

  useEffect(() => {
    function fakeAuth() {
      const { matric, position, Class, centre, token } = getTokenDetails();
      if (position !== "admin") {
        const check = matric || position || centre || Class || token;
        if (check) {
          clearUser();
        }
        navigate("/");
      }
    }

    window.addEventListener("storage", fakeAuth);
    return () => {
      window.removeEventListener("storage", fakeAuth);
    };
  }, [navigate]);

  function handleChange(e) {
    const { name, value, type } = e.target;
    if (name === "search" && type === "search") {
      setSearch(value);
    }
  }

  useEffect(() => {
    setSearch("");
    setShowSearch(false);
    setPreventRefetch(false);
  }, [path]);

  const navStyles =
    path.indexOf("/overview/attendance") > -1
      ? {
          top: "0",
        }
      : {};

  useEffect(() => {
    function changeStatus() {
      if (!navigator.onLine) {
        setOff(true);
      }
    }
    if (!navigator.onLine) {
      setOff(true);
    }
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("offline", changeStatus);
    };
  }, [navigate, path]);

  return (
    <section className="overview admin-overview">
      {off && false && (
        <Offline
          close={() => {
            setOff(false);
          }}
        />
      )}
      <nav style={navStyles}>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="overview"
            >
              Overview
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="teachers"
            >
              Teachers
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="students"
            >
              Students
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="attendance"
            >
              Attendance
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="special"
            >
              Special
            </NavLink>
          </li>
        </ul>
        {showSearch && (
          <div className="student-search">
            <input
              type="search"
              name="search"
              value={search}
              placeholder="Search here..."
              maxLength={20}
              onKeyUp={handleChange}
              onChange={handleChange}
            />
            <FaMagnifyingGlass />
          </div>
        )}
      </nav>
      <Outlet
        context={{
          search,
          record,
          setShowSearch,
          setRecord,
          classRecord,
          setClassRecord,
          currClass,
          setCurrClass,
          currUser,
          setPreventRefetch,
          maxStudents,
          setMaxStudents,
          passMarks,
          setPassMarks,
        }}
      />
    </section>
  );
}

export default AdminOverview;
