import React, { useEffect, useState } from "react";
import ClassCard from "../components/ClassCard";
import { Outlet } from "react-router-dom";
import { getCentresDirect, getFindUs } from "../AppManager";
import { nanoid } from "nanoid";

export default function FindUs() {
  const [justify, setJustify] = useState("flex-start");
  const [findUs, setFindUs] = useState({});

  useEffect(() => {
    getCentresDirect()
      .then((data) => {
        data.map((centre) => {
          getFindUs(centre)
            .then((findus) => {
              let accept = false;
              for (const tclass in findus) {
                if (Object.prototype.hasOwnProperty.call(findus, tclass)) {
                  const tcl = findus[tclass];
                  if (typeof tcl === "object" && tcl.status) {
                    accept = true;
                  }
                }
              }
              accept && setFindUs((old) => ({ ...old, [centre]: findus }));
            })
            .catch((err) => err);
          return centre;
        });
      })
      .catch((err) => err);
  }, []);

  useEffect(() => {
    document.title = "Find Us";
    const width = 210;
    const screenWidth = window.screen.width;
    if (width * 5 > screenWidth - 20) {
      setJustify("flex-start");
    } else {
      setJustify("space-evenly");
    }
    window.addEventListener("resize", () => {
      if (width * 5 > screenWidth - 20) {
        setJustify("flex-start");
      } else {
        setJustify("center");
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (width * 5 > screenWidth - 20) {
          setJustify("flex-start");
        } else {
          setJustify("center");
        }
      });
    };
  }, []);

  return (
    <section className="findus">
      <h1 className="heading1">Find Us</h1>
      <div className="centres">
        <div style={{ justifyContent: justify }}>
          {Object.keys(findUs)
            .sort()
            .map((centre) => (
              <ClassCard
                key={nanoid()}
                name={centre}
                to={`/findus/${centre}`}
                image={`/images/findus/${findUs[centre].image}`}
              />
            ))}
        </div>
      </div>
      <div className="classes">
        <Outlet context={{ findUs }} />
      </div>
    </section>
  );
}
