import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LiaTimesCircle } from "react-icons/lia";
import {
  FaIdCard,
  FaCalendarDays,
  FaChalkboardUser,
  FaSpinner,
} from "react-icons/fa6";
import {
  getClassRecord,
  getCurrTimeInfo,
  stripSpace,
} from "../../../AppManager";
import { nanoid } from "nanoid";

export function findSigned(record, date) {
  for (const student in record) {
    if (Object.prototype.hasOwnProperty.call(record, student)) {
      const stud = record[student];
      for (const day in stud) {
        if (Object.prototype.hasOwnProperty.call(stud, day) && day === date) {
          const status = stud[day];
          if (status !== "ABSENT") return status;
        }
      }
    }
  }
  return false;
}

function SpecialSignIn() {
  const { classes, currUser } = useOutletContext();
  const [formData, setFormData] = useState({
    idnum: "",
    date: "",
    class: "",
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Sign In";
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((old) => ({
      ...old,
      [name]: name === "idnum" ? stripSpace(value.toUpperCase()) : value,
    }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const classDetail = classes.filter(
        (classe) => classe.name === formData.class
      )[0];
      const classRecord = await getClassRecord(
        classDetail.trainingClass,
        formData.class
      );
      if (
        Object.hasOwnProperty.call(classRecord, formData.idnum) ||
        Object.hasOwnProperty.call(
          classRecord,
          formData.idnum.padStart(5, "0")
        ) ||
        (formData.idnum.length === 5 &&
          formData.idnum[0] === "0" &&
          Object.hasOwnProperty.call(classRecord, formData.idnum.slice(1)))
      ) {
        const uidnum = Object.hasOwnProperty.call(classRecord, formData.idnum)
          ? formData.idnum
          : formData.idnum.length === 5 &&
            formData.idnum[0] === "0" &&
            Object.hasOwnProperty.call(classRecord, formData.idnum.slice(1))
          ? formData.idnum.slice(1)
          : formData.idnum.padStart(5, "0");
        const member = classRecord[uidnum];

        const currTimeInfo = getCurrTimeInfo();
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const nani = new Date(formData.date);
        const date = `${days[nani.getDay()]}-${nani
          .getDate()
          .toString()
          .padStart(2, "0")}-${(nani.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${nani.getFullYear()}`;

        let time = findSigned(classRecord, formData.date);
        if (!time) {
          time = currTimeInfo.currTime24;
        }
        time = time.slice(0, 2) + currTimeInfo.currTime.slice(2);

        const status = member[date];
        if (status && status !== "ABSENT") {
          setStatus({
            status: "info",
            type: "failed",
            message: "Already signed in",
          });
          setLoading(false);
        } else {
          setStatus({
            status: "redirect",
            type: "success",
            message: "Redirecting...",
          });
          navigate(`/admin/special/signin/confirm`, {
            state: {
              permission: true,
              idnum: uidnum,
              date,
              time,
              className: formData.class,
              classRecord,
              currUser,
            },
          });
        }
      } else {
        setStatus({
          status: "failed",
          type: "failed",
          message: "Student not Found",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "failed",
        type: "failed",
        message: "Student not Found",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    document.title = "Sign In";
  }, []);

  const selectClasses = classes.map((cent) => (
    <option key={nanoid()} value={cent.name}>
      {cent.name}
    </option>
  ));

  return (
    <div className="formwrapper">
      <div
        className="wrapper"
        style={{ height: status.message !== "" ? "460px" : "420px" }}
      >
        <div className="form-box login">
          <h2>Sign In</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            <div className="input-box">
              <select
                disabled={loading}
                name="class"
                value={formData.class}
                onChange={handleChange}
                className="inputs"
                required
              >
                <option value="" disabled>
                  Choose one...
                </option>
                {selectClasses}
              </select>
              <label>
                <FaChalkboardUser /> Class
              </label>
            </div>
            <div className="input-box">
              <span className="icon">
                <FaCalendarDays />
              </span>
              <input
                type="date"
                name="date"
                className="input"
                value={formData.date}
                max={new Date(new Date().setDate(new Date().getDate() - 1))
                  .toISOString()
                  .slice(0, -14)}
                min={`${new Date().getFullYear() - 1}-01-01`}
                onChange={handleChange}
                required
              />
              <label>Date</label>
            </div>
            <div className="input-box">
              <span className="icon">
                <FaIdCard />
              </span>
              <input
                type="text"
                onChange={handleChange}
                value={formData.idnum}
                autoComplete="off"
                pattern="\d{4,5}|[0-9]{5}[A-Za-z]{2}"
                minLength={4}
                maxLength={7}
                required
                name="idnum"
                style={{ textTransform: "uppercase" }}
                placeholder=" "
              />
              <label>Matric Number</label>
            </div>
            <button type="submit" disabled={loading} className="btn">
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SpecialSignIn;
