import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { extractAdminOverview, showSwal } from "../../AppManager";
import { FaDownload, FaFileExcel, FaSpinner } from "react-icons/fa6";
import { axios, downloadBaseURL } from "../../Auth";

export default function StudentsAnalysis() {
  const { record } = useOutletContext();
  const [analysis, setAnalysis] = useState({
    total: 0,
    "Believers Class": { total: 0 },
    "Baptismal Class": { total: 0 },
    "Workers in Training": { total: 0 },
  });
  const [download, setDownload] = useState({ name: "", state: "", link: "" });

  document.title = "Students Anaylsis";

  const generatorAll = async () => {
    try {
      setDownload({ name: "Total", state: "loading", link: "" });
      const formdata = new FormData();
      formdata.append(
        "believers",
        JSON.stringify(
          Object.keys(analysis["Believers Class"])
            .filter((key) => key !== "total")
            .sort()
        )
      );
      formdata.append(
        "baptismal",
        JSON.stringify(
          Object.keys(analysis["Baptismal Class"])
            .filter((key) => key !== "total")
            .sort()
        )
      );
      formdata.append(
        "workers",
        JSON.stringify(
          Object.keys(analysis["Workers in Training"])
            .filter((key) => key !== "total")
            .sort()
        )
      );
      formdata.append("generate_studs", "njknxankjndkjan");
      const url = "php/downloadStudents.php";
      const send = await axios.post(url, formdata, {
        mode: "cors",
        Headers: {
          "Content-Type": "application/json",
        },
      });

      if (send.data.message === "success") {
        setDownload((old) => ({
          ...old,
          state: "ready",
          link: downloadBaseURL + send.data.link,
        }));
      } else {
        showSwal("error", "Download Failed");
        setDownload({ name: "", state: "", link: "" });
      }
    } catch (error) {
      showSwal("error", "Download Failed");
      setDownload({ name: "", state: "", link: "" });
    }
  };

  const generator = async (name) => {
    try {
      setDownload({ name, state: "loading", link: "" });
      const formdata = new FormData();
      formdata.append("name", name);

      formdata.append(
        "data",
        JSON.stringify(
          Object.keys(analysis[name])
            .filter((key) => key !== "total")
            .sort()
        )
      );
      formdata.append("generate_tclass_studs", "mxwnjsekwq");
      const url = "php/downloadTClassStudents.php";
      const send = await axios.post(url, formdata, {
        mode: "cors",
        Headers: {
          "Content-Type": "application/json",
        },
      });

      if (send.data.message === "success") {
        setDownload((old) => ({
          ...old,
          state: "ready",
          link: downloadBaseURL + send.data.link,
        }));
      } else {
        showSwal("error", "Download Failed");
        setDownload({ name: "", state: "", link: "" });
      }
    } catch (error) {
      showSwal("error", "Download Failed");
      setDownload({ name: "", state: "", link: "" });
    }
  };

  useEffect(() => {
    setAnalysis(extractAdminOverview(record).analysis);
  }, [record]);

  return (
    <div className="overview-class admin-overview-class">
      <h1 className="heading1">Students Analysis</h1>
      <div>
        <div>
          <h1 className="heading3" style={{ textAlign: "left" }}>
            Total:
          </h1>
          <span>
            <span>{analysis.total}</span>
            {download.name === "Total" ? (
              download.state === "ready" ? (
                <a
                  href={download.link}
                  download
                  onClick={() => {
                    setDownload({ name: "", state: "", link: "" });
                  }}
                >
                  <FaFileExcel />
                </a>
              ) : (
                <span>
                  <FaSpinner className="spinner" />
                </span>
              )
            ) : (
              <Link>
                <FaDownload onClick={generatorAll} />
              </Link>
            )}
          </span>
        </div>
        <div>
          <h1 className="heading3" style={{ textAlign: "left" }}>
            Believers Class:
          </h1>
          <span>
            <Link to={"Believers Class"}>
              {analysis["Believers Class"].total}
            </Link>
            {download.name === "Believers Class" ? (
              download.state === "ready" ? (
                <a
                  href={download.link}
                  download
                  onClick={() => {
                    setDownload({ name: "", state: "", link: "" });
                  }}
                >
                  <FaFileExcel />
                </a>
              ) : (
                <span>
                  <FaSpinner className="spinner" />
                </span>
              )
            ) : (
              <Link>
                <FaDownload
                  onClick={() => {
                    generator("Believers Class");
                  }}
                />
              </Link>
            )}
          </span>
        </div>
        <div>
          <h1 className="heading3" style={{ textAlign: "left" }}>
            Baptismal Class:
          </h1>
          <span>
            <Link to={"Baptismal Class"}>
              {analysis["Baptismal Class"].total}
            </Link>
            {download.name === "Baptismal Class" ? (
              download.state === "ready" ? (
                <a
                  href={download.link}
                  download
                  onClick={() => {
                    setDownload({ name: "", state: "", link: "" });
                  }}
                >
                  <FaFileExcel />
                </a>
              ) : (
                <span>
                  <FaSpinner className="spinner" />
                </span>
              )
            ) : (
              <Link>
                <FaDownload
                  onClick={() => {
                    generator("Baptismal Class");
                  }}
                />
              </Link>
            )}
          </span>
        </div>
        <div>
          <h1 className="heading3" style={{ textAlign: "left" }}>
            Workers in Training:
          </h1>
          <span>
            <Link to={"Workers in Training"}>
              {analysis["Workers in Training"].total}
            </Link>
            {download.name === "Workers in Training" ? (
              download.state === "ready" ? (
                <a
                  href={download.link}
                  download
                  onClick={() => {
                    setDownload({ name: "", state: "", link: "" });
                  }}
                >
                  <FaFileExcel />
                </a>
              ) : (
                <span>
                  <FaSpinner className="spinner" />
                </span>
              )
            ) : (
              <Link>
                <FaDownload
                  onClick={() => {
                    generator("Workers in Training");
                  }}
                />
              </Link>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
