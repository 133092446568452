import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  calcEachAtt,
  showSwal,
  specialSignIn,
  updateClassAverage,
} from "../../../AppManager";

function SpecialConfirmSignIn() {
  const { idnum, date, time, className, classRecord } = useLocation().state;
  const navigate = useNavigate();
  const member = classRecord.hasOwnProperty(idnum)
    ? classRecord[idnum]
    : { image: "user.png" };
  const [redirection, setRedirection] = useState("");

  useEffect(() => {
    if (redirection !== "") {
      navigate(redirection.path, redirection.options);
    }
  }, [redirection, navigate]);

  useEffect(() => {
    document.title = "Confirm Sign-In";

    if (!idnum || !date || !time || !className || !classRecord) {
      setRedirection(() => {
        return { path: "/admin/special/signin", options: { replace: true } };
      });
    }
  }, [idnum, date, time, className, classRecord]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      if (Object.hasOwnProperty.call(classRecord, idnum)) {
        const status = member[date];
        window.history.replaceState({}, document.title);
        if (status !== "ABSENT" && classRecord.meetings.includes(date)) {
          showSwal("error", `Already Signed In`);
        } else {
          const fakeMem = classRecord[idnum];
          fakeMem[date] = time;
          const attendance = calcEachAtt(classRecord.meetings, fakeMem) + "%";
          const res = await specialSignIn({
            matric: member.matric,
            className,
            date,
            time,
            attendance,
          });

          if (res === "Signed") {
            updateClassAverage(member.trainingClass, member.class);
            showSwal("success", `Signed In`);
          } else if (res === "Already") {
            showSwal("error", `Already Signed In`);
          } else {
            showSwal("error", "Sign In Failed");
          }
        }
      } else {
        showSwal("error", "Student not Found");
      }
    } catch (error) {
      showSwal("error", "Student not Found");
    }
    setRedirection({
      path: "/admin/special/signin",
      options: { replace: true, state: {} },
    });
  }

  useEffect(() => {
    document.title = "Confirm Sign In";
  }, []);

  return (
    <div className="formwrapper confirmsign">
      <div className="wrapper">
        <div className="form-box login">
          <h2>Confirm Sign In</h2>
          <form onSubmit={handleSubmit}>
            <div className="imgwrap">
              <img
                onError={(e) => {
                  if (e.target.src !== "/user.png") {
                    e.target.onerror = null;
                    e.target.src = "/user.png";
                  }
                }}
                className="img"
                src={`/images/students/${member.image}`}
                alt={member.name}
              />
            </div>
            <p className="heading3">{member.name}</p>
            <input
              type="submit"
              value="confirm"
              name="signin"
              className="btn"
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default SpecialConfirmSignIn;
