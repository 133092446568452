import React from "react";
import { nanoid } from "nanoid";
import "./Christmas.css";

export default function Christmas() {
  //   const snowflakes = ["\u2744", "\u2745", "\u2746"]; // Snowflake characters

  return (
    <div className="christmas">
      <ul className="lightrope">
        {Array.from({ length: 100 }, (_, i) => (
          <li key={nanoid()}></li>
        ))}
      </ul>
      <ul className="lightrope inverse">
        {Array.from({ length: 100 }, (_, i) => (
          <li key={nanoid()}></li>
        ))}
      </ul>
    </div>
  );
}
