import React from "react";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <Link to="/" className="banner-wrapper banner">
      <div className="banner-imgwrap">
        <img src="/images/ruc_logo.png" alt="RUC Logo" />
      </div>
      <div id="banner-wrap">
        <h1 className="h1">RUC</h1>
        <h1 className="h2">Chapel of Power</h1>
        <h1 className="h3">Bible Study Department</h1>
      </div>
      <div className="banner-imgwrap second-imgwrap">
        <img src="/images/Bible Study Icon.png" alt="Bible Study Logo" />
      </div>
    </Link>
  );
}

export default Banner;
