import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { extractAdminOverview, showSwal } from "../../AppManager";
import { FaDownload, FaFileExcel, FaSpinner } from "react-icons/fa6";
import { nanoid } from "nanoid";
import { axios, downloadBaseURL } from "../../Auth";

export default function StudentsAnalysisClasses() {
  const { record } = useOutletContext();
  const [analysis, setAnalysis] = useState({
    total: 0,
  });
  const [download, setDownload] = useState({ name: "", state: "", link: "" });
  const { trainingClass } = useParams();
  const navigate = useNavigate();

  document.title = "Students Anaylsis";

  useEffect(() => {
    if (extractAdminOverview(record).analysis.hasOwnProperty(trainingClass)) {
      setAnalysis(extractAdminOverview(record).analysis[trainingClass]);
    } else {
      navigate("/admin/overview/analysis");
    }
  }, [record, trainingClass, navigate]);

  const generatorAll = async () => {
    try {
      setDownload({ name: "Total", state: "loading", link: "" });
      const formdata = new FormData();
      formdata.append("name", trainingClass);

      formdata.append(
        "data",
        JSON.stringify(
          Object.keys(analysis)
            .filter((key) => key !== "total")
            .sort()
        )
      );
      formdata.append("generate_tclass_studs", "mxwnjsekwq");
      const url = "php/downloadTClassStudents.php";
      const send = await axios.post(url, formdata, {
        mode: "cors",
        Headers: {
          "Content-Type": "application/json",
        },
      });

      if (send.data.message === "success") {
        setDownload((old) => ({
          ...old,
          state: "ready",
          link: downloadBaseURL + send.data.link,
        }));
      } else {
        showSwal("error", "Download Failed");
        setDownload({ name: "", state: "", link: "" });
      }
    } catch (error) {
      showSwal("error", "Download Failed");
      setDownload({ name: "", state: "", link: "" });
    }
  };

  const generator = async (name) => {
    try {
      setDownload({ name, state: "loading", link: "" });
      const formdata = new FormData();
      formdata.append("name", name);
      formdata.append("generate_class_studs", "smd3en93nini2");
      const url = "php/downloadClassStudents.php";
      const send = await axios.post(url, formdata, {
        mode: "cors",
        Headers: {
          "Content-Type": "application/json",
        },
      });

      if (send.data.message === "success") {
        setDownload((old) => ({
          ...old,
          state: "ready",
          link: downloadBaseURL + send.data.link,
        }));
      } else {
        showSwal("error", "Download Failed");
        setDownload({ name: "", state: "", link: "" });
      }
    } catch (error) {
      showSwal("error", "Download Failed");
      setDownload({ name: "", state: "", link: "" });
    }
  };

  const elements = Object.keys(analysis)
    .filter((key) => key !== "total")
    .sort()
    .map((key) => (
      <div key={nanoid()}>
        <h1 className="heading3" style={{ textAlign: "left" }}>
          {key}:
        </h1>
        <span>
          <span>{analysis[key]}</span>
          {download.name === key ? (
            download.state === "ready" ? (
              <a
                href={download.link}
                download
                onClick={() => {
                  setDownload({ name: "", data: [], state: "", link: "" });
                }}
              >
                <FaFileExcel />
              </a>
            ) : (
              <span>
                <FaSpinner className="spinner" />
              </span>
            )
          ) : (
            <Link>
              <FaDownload onClick={() => generator(key)} />
            </Link>
          )}
        </span>
      </div>
    ));

  return (
    <div className="overview-class admin-overview-class">
      <h1 className="heading1">{trainingClass}</h1>
      <div>
        <div>
          <h1 className="heading3" style={{ textAlign: "left" }}>
            Total:
          </h1>
          <span>
            <span>{analysis.total}</span>
            {download.name === "Total" ? (
              download.state === "ready" ? (
                <a
                  href={download.link}
                  onClick={() => {
                    setDownload({ name: "", data: [], state: "", link: "" });
                  }}
                >
                  <FaFileExcel />
                </a>
              ) : (
                <span>
                  <FaSpinner className="spinner" />
                </span>
              )
            ) : (
              <Link>
                <FaDownload onClick={generatorAll} />
              </Link>
            )}
          </span>
        </div>
        {elements}
      </div>
    </div>
  );
}
