import React, { useEffect, useState } from "react";
import {
  FaChalkboardUser,
  FaChurch,
  FaCircleInfo,
  FaImage,
  FaLocationDot,
  FaSpinner,
} from "react-icons/fa6";
import { LiaTimesCircle } from "react-icons/lia";
import { useOutletContext } from "react-router-dom";
import {
  allTrainingClasses,
  checkExtension,
  extractAllCentres,
  getFindUs,
  setFindUs,
} from "../../../AppManager";
import { nanoid } from "nanoid";

export default function SetFindUs() {
  const { record } = useOutletContext();
  const [formData, setFormData] = useState({
    trainingClass: "",
    centre: "",
    location: "",
    image: "",
    status: "true",
  });
  const [centres, setCentres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: "", type: "", message: "" });
  const [imgErr, setImgErr] = useState({
    name: "",
    reason: "",
    status: true,
  });

  function handleChange(e) {
    const { name, value, type, files } = e.target;
    setFormData((oldFormData) => {
      if (type === "file" && files[0]) {
        if (files[0].size <= 512000 && checkExtension(files[0].name)) {
          setImgErr({ name: "", reason: "", status: true });
          return { ...oldFormData, [name]: type === "file" ? files[0] : value };
        } else {
          if (files[0].size > 512000) {
            setImgErr({ name, reason: "size", status: false });
          } else if (!checkExtension(files[0].name)) {
            setImgErr({ name, reason: "format", status: false });
          }
        }
        return oldFormData;
      } else {
        return { ...oldFormData, [name]: value };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      let data = formData;
      if (formData.trainingClass === "General") {
        data = { ...formData, status: "", location: "" };
      } else {
        if (formData.status === "false") {
          data = { ...formData, image: "", location: "" };
        }
      }
      if (formData.trainingClass !== "General" && !formData.image) {
        const old = await getFindUs(formData.centre);
        const oldimg = old[formData.trainingClass]?.image;
        data = { ...data, image: oldimg };
      }
      const set = await setFindUs(data);

      if (set === "Success") {
        setStatus({
          status: "success",
          type: "success",
          message: "Update Successful",
        });
        setLoading(false);
        setFormData({
          ...formData,
          trainingClass: "",
          centre: "",
          location: "",
          status: "true",
        });
      } else {
        setStatus({
          status: "error",
          type: "failed",
          message: "Update Failed",
        });
        setLoading(false);
      }
    } catch (error) {
      setStatus({
        status: "error",
        type: "failed",
        message: "Update Failed",
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    if (status.message !== "") {
      document
        .getElementById("status-wrapper")
        .scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [status]);

  useEffect(() => {
    setCentres(extractAllCentres(record).sort());
  }, [record]);

  document.title = "Set Find Us";

  const selectCentres = centres.map((cent) => (
    <option key={nanoid()} value={cent}>
      {cent}
    </option>
  ));

  const selectTclasses = allTrainingClasses.map((tclass) => (
    <option key={nanoid()} value={tclass}>
      {tclass}
    </option>
  ));

  return (
    <div className="formwrapper" style={{ margin: "50px 0" }}>
      <div
        className="wrapper"
        style={{
          height:
            formData.status === "true" && formData.trainingClass !== "General"
              ? status.message
                ? "640px"
                : "600px"
              : status.message
              ? "490px"
              : "450px",
        }}
      >
        <div className="form-box login">
          <h2>Set Find Us</h2>
          <form onSubmit={handleSubmit}>
            {status.message !== "" && (
              <p className="status-wrapper" id="status-wrapper">
                <span className={`status-message ${status.type}`}>
                  {status.message}
                </span>
                <LiaTimesCircle
                  className="status-close"
                  onClick={() => {
                    setStatus({ status: "", type: "", message: "" });
                  }}
                />
              </p>
            )}
            <div className="input-box">
              <select
                disabled={loading}
                name="centre"
                value={formData.centre}
                onChange={handleChange}
                className="inputs"
                required
              >
                <option value="" disabled>
                  Choose one...
                </option>
                {selectCentres}
              </select>
              <label>
                <FaChurch /> Centre
              </label>
            </div>
            <div className="input-box">
              <select
                disabled={loading}
                name="trainingClass"
                value={formData.trainingClass}
                onChange={handleChange}
                className="inputs"
                required
              >
                <option value="" disabled>
                  Choose one...
                </option>
                <option value="General">General</option>
                {selectTclasses}
              </select>
              <label>
                <FaChalkboardUser /> Training Class
              </label>
            </div>
            {formData.status === "true" &&
              formData.trainingClass !== "General" && (
                <div className="input-box">
                  <span className="icon">
                    <FaLocationDot />
                  </span>
                  <input
                    readOnly={loading}
                    placeholder=" "
                    type="text"
                    value={formData.location}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    maxLength={50}
                    name="location"
                  />
                  <label>Location</label>
                </div>
              )}
            {(formData.status === "true" ||
              formData.trainingClass === "General") && (
              <div className="input-box">
                <span className="icon">
                  <FaImage />
                </span>
                <input
                  disabled={loading}
                  type="file"
                  name="image"
                  onChange={handleChange}
                  id="uuploadedfile"
                  className="input"
                  accept="image/*"
                  style={{ marginTop: "5px" }}
                  required={formData.trainingClass === "General"}
                />
                <label>
                  Location Image{" "}
                  <span style={{ fontSize: "0.7em" }}>(max = 500kb)</span>
                </label>
                {imgErr.name === "image" &&
                  imgErr.reason === "size" &&
                  !imgErr.status && (
                    <p style={{ marginTop: "0px" }}>Max Size Exceeded</p>
                  )}
                {imgErr.name === "image" &&
                  imgErr.reason === "format" &&
                  !imgErr.status && (
                    <p style={{ marginTop: "0px" }}>Invalid File Format</p>
                  )}
              </div>
            )}
            {formData.trainingClass !== "General" && (
              <div className="input-box">
                <select
                  disabled={loading}
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="inputs"
                  required
                >
                  <option value="" disabled>
                    Choose one...
                  </option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <label>
                  <FaCircleInfo /> Show Training Class
                </label>
              </div>
            )}
            <button
              type="submit"
              disabled={!imgErr.status || loading}
              className="btn"
            >
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
