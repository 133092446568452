import React from "react";
import ClassCard from "../components/ClassCard";
import { useOutletContext, useParams } from "react-router-dom";

export default function FindUsOutlet() {
  let { centre } = useParams();
  const { findUs } = useOutletContext();
  centre = centre ? centre : Object.keys(findUs).sort()[0];

  return (
    <>
      <h2 className="heading2">{centre} Classes Location</h2>
      {findUs[centre] && findUs[centre]["believers class"].status && (
        <div>
          <ClassCard
            name={"Believers Class"}
            to={""}
            image={`/images/findus/${findUs[centre]["believers class"].image}`}
          />
          <span>
            <strong>Location:</strong>{" "}
            {findUs[centre]["believers class"].location}
          </span>
        </div>
      )}
      {findUs[centre] && findUs[centre]["baptismal class"].status && (
        <div>
          <ClassCard
            name={"Baptismal Class"}
            to={""}
            image={`/images/findus/${findUs[centre]["baptismal class"].image}`}
          />
          <span>
            <strong>Location:</strong>{" "}
            {findUs[centre]["baptismal class"].location}
          </span>
        </div>
      )}
      {findUs[centre] && findUs[centre]["workers in training"].status && (
        <div>
          <ClassCard
            name={"Workers in Training"}
            to={""}
            image={`/images/findus/${findUs[centre]["workers in training"].image}`}
          />
          <span>
            <strong>Location:</strong>{" "}
            {findUs[centre]["workers in training"].location}
          </span>
        </div>
      )}
    </>
  );
}
