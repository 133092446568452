import React, { useEffect, useState } from "react";
import { FaImage, FaTrash } from "react-icons/fa6";
import { Link, useOutletContext } from "react-router-dom";
import { deleteCarousel, getCarousels, showSwal } from "../../../AppManager";
import ConfirmAction from "../../../components/ConfirmAction";
import { nanoid } from "nanoid";

export default function HomeImages() {
  const { currUser } = useOutletContext();
  const [carousels, setCarousels] = useState([
    "carousel_1.jpg",
    "carousel_2.jpg",
    "carousel_3.jpg",
    "carousel_4.jpg",
  ]);
  const [close, setClose] = useState(true);
  const [id, setId] = useState("");

  useEffect(() => {
    getCarousels()
      .then((images) => images.length !== 0 && setCarousels(images))
      .catch((err) => err);
  }, []);

  async function deleteImage() {
    try {
      const del = await deleteCarousel(id, carousels[id]);
      if (del === "Success") {
        setCarousels((old) =>
          old.map((val, index) => (index === id ? "" : val))
        );
      } else {
        showSwal("error", "Delete Failed");
      }
    } catch (error) {
      showSwal("error", "Delete Failed");
    }
  }

  return (
    <div className="home-images">
      {!close && id && (
        <ConfirmAction
          currUser={currUser}
          setClose={setClose}
          confirmed={deleteImage}
        />
      )}
      <h1 className="heading1">Home Images</h1>
      <div>
        {Array.from({ length: 4 }, (_, i) => (
          <span key={nanoid()} className="class-card">
            <img
              onError={(e) => {
                if (e.target.src !== "/images/classroom.jpg") {
                  e.target.onerror = null;
                  e.target.src = "/images/classroom.jpg";
                }
              }}
              src={`/images/carousels/${carousels[i]}`}
              alt={`Carousel ${i + 1}`}
            />
            <span>
              <Link to={`edit/${i}`}>
                <FaImage />
              </Link>
            </span>
          </span>
        ))}
        {Array.from({ length: 2 }, (_, i) => (
          <span key={nanoid()} className="class-card">
            <img
              onError={(e) => {
                if (e.target.src !== "/images/classroom.jpg") {
                  e.target.onerror = null;
                  e.target.src = "/images/classroom.jpg";
                }
              }}
              src={`/images/carousels/${carousels[i + 4]}`}
              alt={`Carousel ${i + 5}`}
            />
            <span>
              <Link to={`edit/${i + 4}`}>
                <FaImage />
              </Link>
              {carousels[i + 4] && (
                <FaTrash
                  onClick={() => {
                    setClose(false);
                    setId(i + 4);
                  }}
                />
              )}
            </span>
          </span>
        ))}
      </div>
    </div>
  );
}
